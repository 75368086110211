import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
function DistributionTicketOrganisateur() {
    const initialeValues = {
        "id": "",
        "partenaire" : "",
        "nbreTicket" : ""
    }

    const validationSchemaValues = yup.object().shape({
        "partenaire" : yup.string().required("Veuillez Sélectionner un partenaire"),
        "nbreTicket" : yup.number().required("Veuillez saisir un nombre").min(1, "Veuillez saisir un nombre supérieur à 0")
    })

    const onSubmitForm = (values)=>{
        console.log(values);
    }

    return (
        <div className="modal fade" id="distribution" tabIndex={-1} role="dialog" aria-labelledby="distribution" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Attribution des tickets</h5>
                        <button type="button" className="btn close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={initialeValues}
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                   
                                    <div className='mb-3'>
                                        <label className='form-label'>Partenaire</label>
                                        <Field component="select" className="form-select" name="partenaire" id="partenaire">
                                            <option value="">Sélectionner un partenaire</option>
                                            <option value="MTN CI">MTN CI</option>
                                            <option value="ORANGE CI">ORANGE CI</option>
                                            <option value="MOOV AFRICA">MOOV AFRICA</option>
                                        </Field>
                                        <p className='text-danger'><ErrorMessage name='partenaire'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label'>Nombre de tickets</label>
                                        <Field type="number" className="form-control" placeholder="Veuillez saisir un nombre" name="nbreTicket" id="nbreTicket" />
                                        <p className='text-danger'><ErrorMessage name='nbreTicket'></ErrorMessage></p>
                                    </div>
                                    <div className='d-flex justify-content-end'>
                                        <button type="button" onClick={resetForm} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Fermer</button>
                                        <button type="submit" className="btn bg-navbar text-white">Enregistrer</button>
                                    </div>

                                </Form>
                            )}
                        </Formik>
                    </div>
                    
                </div>
            </div>
        </div >
    )
}

export default DistributionTicketOrganisateur