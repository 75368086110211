import React from 'react'
import NavBar from './NavBar'

function Layouts(props) {
    return (<>
        <NavBar />
        <div className='bg-white m-0'>
            {props.children}
        </div>
    </>
    )
}

export default Layouts