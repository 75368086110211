import React, { useEffect,useState } from 'react'
import { useDropzone } from 'react-dropzone';
import $ from 'jquery';
function UploadFile(props) {

    let [fichier,setFichiers] = useState([]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept : "text/*,"
    });
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
          {file.path} - {file.size} Bytes
        </li>
      ));

      const fileChange = (event) =>{
          console.log(event.target.files)
          fichier = event.target.files[0];
          setFichiers(fichier);
          props.setinfoFile(event.target.files[0])
         // props.miseAjour(event.target.files[0])
          console.log(fichier);
      }

      const choisirfichier = ()=>{
        $('#fichier').trigger('click');
      }

    //   useEffect(() => {
    //     acceptedFiles.length != 0 && props.setinfoFile(acceptedFiles);
    //   }, [acceptedFiles])

        useEffect(() => {
        fichier.length != 0 && props.setinfoFile(fichier);
      }, [fichier])

    
      
    return (
        <section className="container text-center"
            style={{ "border": "2px dotted black", "backgroundColor":"#303dac52" }}
        >
            {/* <div {...getRootProps({ className: 'dropzone  py-2' })}>
                <input {...getInputProps()} />
                <i className="bi bi-cloud-arrow-up fs-1"></i>
                <p>Cliquez ici pour ajouter votre fichier CSV</p>
            </div> */}

            <div onClick={choisirfichier} className='pt-3 pb-2' >
                <input id='fichier' type="file" className='form-control' onChange={fileChange} />
                {/* <i className="bi bi-cloud-arrow-up fs-1"></i> */}
               {fichier.length == 0 && <p>Cliquez ici pour ajouter votre fichier CSV</p>}
            </div>
            <aside className='text-primary'>
                {files}
            </aside>
        </section>
    )
}

export default UploadFile