import { BrowserRouter,HashRouter } from 'react-router-dom';
import './App.css';
import RouterApp from './RouterApp';

function App() {
  return (
    <HashRouter hashType="slash">
      <RouterApp />
    </HashRouter>
  )
}

export default App;
