import React, { useState } from 'react'
import Layouts from '../layouts/Layouts'
import EntrepriseAdd from './EntrepriseAdd';

function Entreprises() {
    const [datas, setdatas] = useState([
        {
            "nom": "MTN Côte d'Ivoire",
            "email": "contact@mtn.ci",
            "statut": "ACTIF"
        },

        {
            "nom": "Orange Côte d'Ivoire",
            "email": "contact@orange.ci",
            "statut": "ACTIF"
        },

        {
            "nom": "Moov Côte d'Ivoire",
            "email": "contact@moov.ci",
            "statut": "ACTIF"
        },

       
    ]);

    const rowListeEntreprises= datas.map((item, index) => (
        <tr key={index}>
            <td>{item.nom}</td>
            <td>{item.email}</td>
            <td width="10%" className='p-3 text-center'>
                <span className={item.statut == "ACTIF" ? "bg-success text-white p-2 rounded" : "bg-danger text-white p-2 rounded"}>
                    <i className={item.statut == "ACTIF" ? "bi bi-check-circle-fill" : "bi bi-x-circle-fill"}></i> {item.statut == "ACTIF" ? "Actif" : "Inactif"}
                </span>
            </td>
        </tr>
    ))

    return (
        <Layouts>

            <div className='mx-3 mt-3'>
                {/* <div className='d-flex'>
              <p>Nombre d'entreprises : <span className='nb'> 100</span></p>
              <p className='mx-3'>Nombre d'organisateurs : <span className='nb'> 100</span></p>
            </div> */}
                <div className='d-flex py-2 justify-content-between'>
                    <div></div>
                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <input type="text" className="form-control" placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
                            <div className="input-group-prepend">
                                <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button className='btn btn-sm bg-navbar text-white' data-bs-toggle="modal" data-bs-target="#EntrepriseAdd"> <i className="bi bi-plus-circle-fill"></i> Nouveau </button>
                        <EntrepriseAdd/>
                    </div>
                </div>

                <table className='table table-hover table-bordered '>
                    <thead>
                        <tr className=' text-white text-bold bg-navbar'>
                            <th>Nom</th>
                            <th>Email</th>
                            <th className='text-center'>Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowListeEntreprises}
                    </tbody>
                </table>
                <div className='d-flex justify-content-end'>
                    <nav aria-label="Pagination">
                        <ul className="pagination pagination-sm">
                            <li className="page-item disabled">
                                <span className="page-link">Previous</span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active">
                                <span className="page-link">
                                    2
                                    <span className="sr-only"></span>
                                </span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>

        </Layouts>
    )
}

export default Entreprises