import jwt_decode from 'jwt-decode';

export const isAuthenticated = () => {

  let tokenValid = false;
  try {
    if (localStorage.getItem("skanbusinesstoken").replace(/[\""]+/g, '')) {
      if (
        Date.now() <= jwt_decode(localStorage.getItem("skanbusinesstoken").replace(/[\""]+/g, '')).exp*1000
      ) {
        tokenValid = true
      } else {
        tokenValid = false;
        localStorage.removeItem("skanbusinesstoken");
      }
    }
  } catch (error) {
    tokenValid = false

  }
  return tokenValid;

}