import React, { useState,useEffect } from 'react'
import { Link, Navigate, NavLink } from 'react-router-dom'
import DistribuerTicket from './DistribuerTicket'
import  {rechercherticketparpartenaire} from '../../services/billeteries/BilleterieRequest'
import Evenement from '../Evenements/Evenement';
import { Oval } from 'react-loader-spinner';
import { enregistrerConfigurationPrixVenteTicket } from '../../services/billeteries/BilleterieRequest';
import { rechercherevents, recherchereventsParam } from '../../services/evenements/Evenement';
import { data } from 'jquery';
import { URLAPI } from "../../components/Constantes/Api"

function BilleterieEntreprise(props) {
  let [events,setEvents] = useState([]);
  let [datas, setdatas] = useState({})
  const [Isevenement, setIsevenement] = useState(false);
  const [loading, setloading] = useState(false);
  let [evenementId, setevenementId] = useState("");
  let [prix, setPrix] = useState("");

  useEffect(() => {
  rechercherTickets(props.id);
  rechercherEvent(props.id);
  
  }, []);


  const submitConfigPrix = () =>{

    

   let submitObject = {
    evenementId:evenementId,
    prix:prix
   }
    enregistrerConfigurationPrixVenteTicket(submitObject).then((reponse)=>{
       setloading(false);
       setevenementId("");
       setPrix("");
       rechercherTickets(props.id);
       
    }).catch((error)=>{
        setloading(false);
        console.log(error.response.data.donnee);
    })


}

  const rechercherTickets = (id)=>{
    rechercherticketparpartenaire(id).then((reponse)=>{
      setdatas(reponse.data.donnee)
      console.log(datas)
      console.log(reponse.data.donnee)
      //setEvents(reponse.data.donnee.eventstickets);
    }).catch(error=>{
      console.log("Erreur lors de la recupération des informations");
    })
  }



  const rechercherEvent = (id)=>{
    recherchereventsParam(id).then((reponse)=>{
      console.log("EVENTS")
      console.log(reponse.data.donnee)
      setEvents(reponse.data.donnee);
      console.log(events)
    }).catch(error=>{
      console.log("Erreur lors de la recupération des informations");
    })
  }



  const rowListeBilleriesEntreprises = events.map((item, index) => (
    <tr key={index}>
      <td><Link className='cursor displayBlock' to={item.id}> <img src={`${URLAPI}/fichier/${item.visuelId}`} alt={item.designation} width="30" className='img-fluid' /></Link>  </td>
      <td><Link className='cursor displayBlock' to={item.id}> {item.designation} </Link></td>
      <td width="15%"><Link className='cursor displayBlock' to={item.id}> {item.nombreTicketReçu} </Link></td>
      <td width="15%"><Link className='cursor displayBlock' to={item.id}> {item.nombreTicketReçu - item.nombreTicketDistribues} </Link></td>
      {/* <td width="15%"><Link className='cursor displayBlock' to={item.id}> {0} </Link></td> */}
    </tr>
  ))
  return (
    <>
      <div className='m-2'>
        <Link to={"/billeterie"} style={{ color: "#3B546E", borderBottomColor: "white" }}>
          <i className="bi fs-6 bi-arrow-left-circle-fill"></i> Retour
        </Link>
      </div>
      <div className='d-flex justify-content-between mx-2 mb-3'>
        <div className='mt-4'>
          Partenaire : <span className='fw-bold fs-6' style={{ color: "#3B546E" }}>{datas.organisateur}</span> <i className="bi bi-slash-lg"></i> Tickets reçu : <span className='fw-bold'>{datas.quantite}</span> <i className="bi bi-slash-lg"></i> Tickets restant : <span className='fw-bold'>{datas.quantite - datas.nombreTicketDistribues}</span> 
        </div>
        {/* <div className='px-3 pt-3 mr-5'>
        <button data-bs-toggle="modal" data-bs-target="#prixVente" className='btn text-white bg-navbar mr-5'> Prix vente</button>
         
        </div> */}
        <div className='px-3 pt-3'>
        
          {/* col-md-5 rounded d-flex justify-content-between text-white bg-GlobalTickets
           <p className='text-bold'>Nombre de ticket reçu du partenaire: <span className='fs-5 text-bold'>2000</span></p> */}
          <button data-bs-toggle="modal" data-bs-target="#distribution" className='btn text-white bg-navbar'><i className="bi bi-share"></i> Distribuer des tickets</button>
        </div>

        <DistribuerTicket />
      </div>

      <table className='table table-hover table-bordered '>
        <thead>
          <tr className=' text-white text-bold bg-navbar'>
            <th>Affiche Event</th>
            <th>Evènement</th>
            <th>Tickets reçu</th>
            <th>Tickets restant</th>
            {/* <th>Prix vente(F CFA)</th> */}
          </tr>
        </thead>
        <tbody>
          {rowListeBilleriesEntreprises}

        </tbody>
      </table>
      <DistribuerTicket />
      {/* <div className='d-flex justify-content-end'>
        <nav aria-label="Pagination">
          <ul className="pagination pagination-sm">
            <li className="page-item disabled">
              <span className="page-link">Previous</span>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item active">
              <span className="page-link">
                2
                <span className="sr-only"></span>
              </span>
            </li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item">
              <a className="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav>
      </div> */}



      <div className="modal fade" id="prixVente" tabIndex={-1} role="dialog" aria-labelledby="prixVente" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Configurer prix de vente tickets</h5>
                        <button type="button" id='close' className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                    
                    </div>
                    <div className='mb-3'>
                        <div className="form-check mb-3 mx-3 form-switch">
                            <input className="form-check-input" onChange={() => setIsevenement(!Isevenement)} type="checkbox" id="flexSwitchCheckChecked" checked={Isevenement} />
                            <label htmlFor="">Ticket evenement</label>
                        </div>
                        
                    </div>
                    {Isevenement && 
                    <div className='mb-3 mx-3'>
                                <label htmlFor="nbTicket" className='label-control'>Selectionnez l'evenement</label>
                                <select onChange={(e)=>setevenementId(e.target.value)} value={evenementId} className='form-select'>
                                  <option value=""></option>
                                  {
                                    events.map((ev,i)=>(
                                      <option value={ev.id} key={i}>{ev.designation}</option>
                                    ))
                                  }
                                </select>
                            
                    </div>
                     }
                    <div className='mb-3 mx-3'>
                                <label htmlFor="nbTicket" className='label-control'>Prix de vente</label>
                                <input className="form-control" onChange={(e)=>setPrix(e.target.value)} value={prix}   type="number" id="nbTicket" />
                    </div>
                    
                    <div className="modal-footer text-end">
                    {loading ? <Oval with="30" height="30" color="#344b7b" visible={loading}/> :
                        <button type='submit' onClick={submitConfigPrix} className='btn bg-navbar text-white'>Valider</button>
                    }
                    </div>
                </div>
            </div>
        </div >
    </>
  )
}

export default BilleterieEntreprise