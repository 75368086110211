import { URLAPI } from "../../components/Constantes/Api"
import axios from "axios"

export const rechercherUtilisateurs = async () => {
    return await axios.get(`${URLAPI}/business/rechercherutilisateurs?page=0&size=20`);
}

export const rechercherutilisateurparparam = async (param) => {
    return await axios.post(`${URLAPI}/business/rechercherutilisateurparparam?param=${param}&page=0&size=10`, {});
}

export const signin = async(values)=>{
    return await axios.post(`${URLAPI}/signin`, values);
} 

export const registreruser = async(values)=>{
    return axios.post(`${URLAPI}/signup`, values);
}
