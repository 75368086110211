import React, { useState } from 'react'
import { useEffect } from 'react';
import { rechercherevents } from '../../services/evenements/Evenement';
import Layouts from '../layouts/Layouts'
import { URLAPI } from "../../components/Constantes/Api"
function Evenement() {

  const [datas, setdatas] = useState([]);

  const rechercherEvents = ()=>{
    rechercherevents().then((reponse)=>{
      setdatas(reponse.data.donnee);
    })
  }

  useEffect(() => {
    rechercherEvents();
  }, [])
  

  const cardItemEvent = datas.map((item, index) => (
    <div key={index} className="card col-md-3 mx-3 shadow mb-3">
      <img style={{ width: "100%" }} src={`${URLAPI}/fichier/${item.visuelId}`} className="card-img-top" alt={item.designation} />
      <div className="card-body">
        <h5 className="card-title">{item.designation}</h5>
      </div>
      <div className='card-footer d-flex justify-content-between bg-white'>
        <p className="card-text text-bold text-success">{item.nombreTicketReçu} tickets <small className='text-secondary'>reçu</small> </p>
        <p className="card-text"><small className="text-muted">Date : {item.date}</small></p>
      </div>
    </div>
  ))

  return (
    <Layouts>
      <h5 className='text-center mt-3 mb-3'>Mes évènement en cours ...</h5>
      <div className='row justify-content-center'>
        {cardItemEvent}
      </div>
    </Layouts>
  )
}

export default Evenement