import React from 'react'
import Layouts from '../layouts/Layouts'
import BilleterieEntreprise from './BilleterieEntreprise'
import { useParams } from 'react-router-dom';

function DetailsBilleterie(props) {
    const params = useParams()
    return (
        <Layouts>
            <div className='m-2 '>
                <BilleterieEntreprise id={params.details} />
            </div>
        </Layouts>
    )
}

export default DetailsBilleterie