import { URLAPI } from "../../components/Constantes/Api"
import axios from "axios"

export const rechercherTickets = async () => {
    return await axios.get(`${URLAPI}/business/recherchertickets`);
}

export const rechercherticketsparparam = async (param) => {
    return await axios.post(`${URLAPI}/business/rechercherticketsparparam?param=${param}`, {});
}

export const distribuerticket = async(param)=>{
    
    //return await axios.post(`${URLAPI}/business/distribuerticket`, param);
   return await axios({
        url: `${URLAPI}/business/distribuerticket`, //your url
        method: 'POST',
        responseType: 'blob', // important
        data:param
    });
}
export const enregistrerConfigurationPrixVenteTicket = async(param)=>{
    return await axios.post(`${URLAPI}/business/enregistrerprixventetickets`, param);
}
export const rechercherticketparpartenaire = async(id)=>{
    return await axios.get(`${URLAPI}/business/rechercherticketparpartenaire/${id}`);
}

export const rechercherbeneficiairestickets = async(id)=>{
    return await axios.get(`${URLAPI}/business/rechercherbeneficiairestickets/${id}`);
}
