import React, { useEffect, useState } from 'react'
import { enregistreremployes, verifierfichier } from '../../services/Employes/EmployeRequest';
import UploadFile from '../UploadFile'
import {Oval} from 'react-loader-spinner'

function ChargerEmployes(props) {
    const [infoFileUploaded, setinfoFileUploaded] = useState([]);
    const [infosEmployes, setinfosEmployes] = useState({});
    const [messageError, setmessageError] = useState("");
    const [loading, setloading] = useState(false);
    //Enregistrement des employes
    const SendDataFile = (params) => {
        console.log(params[0])
        let param = new FormData();
        param.append("fichiercsv", params);
        verifierfichier(param).then((res) => {
            setmessageError("");
            setinfosEmployes(res.data.donnee);
        }).catch((error)=>{
            setmessageError(error.response.data.donnee);
        });

    }

    const uploadFile = () => {
        setloading(true);
        let param = new FormData();
        param.append("fichiercsv", infoFileUploaded[0]);
        enregistreremployes(param).then((res)=>{
            setloading(false);
            setinfoFileUploaded([]);
            setinfosEmployes({});
            props.actualiserliste();
            document.getElementById('clodemodalemploye').click();
        }).catch((error)=>{
            setloading(false);
        });
    }

    useEffect(() => {
        (infoFileUploaded.length !== 0) && SendDataFile(infoFileUploaded);
    }, [infoFileUploaded]);

    return (
        <div className="modal fade" id="charger" tabIndex={-1} role="dialog" aria-labelledby="charger" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Charger la liste des employés</h5>
                        <button type="button" id='clodemodalemploye' onClick={()=>{setinfoFileUploaded([]);}} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            {messageError !== "" && <p className='text-danger text-bold'>{messageError}</p>}
                        </div>
                        <UploadFile setinfoFile={setinfoFileUploaded} />
                        <><h6 className='my-3' style={{ textDecoration: "underline" }}>Details fichier</h6>
                            <div className='d-flex mx-2 me-1'>
                                <div className='bg-success text-white rounded p-2 mb-3'><i className="bi bi-check-circle-fill fs-5"></i><span className='fs-5'> {infosEmployes.nombreEmployeValid !== undefined && infosEmployes.nombreEmployeValid} </span> Valides</div>
                                <div className='bg-danger text-white mx-2 rounded mb-3 p-2'><i className="bi bi-x-circle-fill fs-5"></i> <span className='fs-5'> {infosEmployes.nombreEmployeInvalid !== undefined && infosEmployes.nombreEmployeInvalid}</span> Invalides </div>
                            </div>
                        </>
                    </div>
                    <div className="modal-footer text-end">
                        {loading ? <Oval with="30" height="30" color="#344b7b" visible={loading}/> :
                            <button className='btn bg-navbar text-white' onClick={uploadFile}>Valider</button>
                        }
                    </div>
                </div>
            </div>
        </div >
    )
}

export default ChargerEmployes