import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import DistributionTicketOrganisateur from './DistributionTicketOrganisateur'

function BilleterieOrganisateur() {
  const [datas, setdatas] = useState([
    {
      "id": "1",
      "photo": "https://lifemag-ci.com/wp-content/uploads/2020/12/singuila.jpg",
      "nomEvent": "Concert Singuila",
      "partenaire": "",
      "ticketsDisponible": "2000",
      "ticketsRestant": "1000",
    }
  ])

  const rowListeBilleriesEOrganisateur = datas.map((item, index) => (
    <tr key={index}>
      <td width="15%"><NavLink className='cursor displayBlock' to={"/billeterie/organisateur/" + item.id}> <img src={item.photo} alt="Affiche évènement" width="30" className='img-fluid' /></NavLink>  </td>
      <td><NavLink className='cursor displayBlock' to={"/billeterie/organisateur/" + item.id}> {item.nomEvent} </NavLink></td>
      <td width="15%"><NavLink className='cursor displayBlock' to={"/billeterie/organisateur/" + item.id}> {item.ticketsDisponible} </NavLink></td>
      <td width="15%"><NavLink className='cursor displayBlock' to={"/billeterie/organisateur/" + item.id}> {item.ticketsRestant} </NavLink></td>
      <td className='text-center' width="15%"><button data-bs-toggle="modal" data-bs-target="#distribution" className='bg-navbar btn btn-sm text-white'> <i className="bi bi-share"></i> Attribuer Tickets</button></td>
    </tr>
  ))
  return (
    <>
      <div className='d-flex py-2 justify-content-between'>
        <div></div>

        <div>
          <div className="input-group input-group-sm mb-1">
            <input type="text" className="form-control" placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
            <div className="input-group-prepend">
              <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
            </div>
          </div>
        </div>

        <div> </div>
      </div>

      <table className='table table-hover table-bordered '>
        <thead>
          <tr className=' text-white text-bold bg-navbar'>
            <th>Affiche Event</th>
            <th>Evènement</th>
            <th>Tickets total</th>
            <th>Tickets disponible</th>
            <th className='text-center'>Action rapide</th>
          </tr>
        </thead>
        <tbody>
          {rowListeBilleriesEOrganisateur}
        </tbody>
      </table>
      <DistributionTicketOrganisateur />
      <div className='d-flex justify-content-end'>
        <nav aria-label="Pagination">
          <ul className="pagination pagination-sm">
            <li className="page-item disabled">
              <span className="page-link">Previous</span>
            </li>
            <li className="page-item"><a className="page-link" href="#">1</a></li>
            <li className="page-item active">
              <span className="page-link">
                2
                <span className="sr-only"></span>
              </span>
            </li>
            <li className="page-item"><a className="page-link" href="#">3</a></li>
            <li className="page-item">
              <a className="page-link" href="#">Next</a>
            </li>
          </ul>
        </nav>
      </div>

    </>
  )
}

export default BilleterieOrganisateur