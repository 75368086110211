import { URLAPI } from "../../components/Constantes/Api"
import axios from "axios"

export const rechercherPartenaires = async () => {
    return await axios.get(`${URLAPI}/business/rechercherpartenaires`);
}

export const rechercherpartenaireparparam = async (param) => {
    return await axios.post(`${URLAPI}/business/rechercherpartenaireparparam?param=${param}`, {});
}

