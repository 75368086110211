import { URLAPI } from "../../components/Constantes/Api"
import axios from "axios"

export const rechercherEmployes = async () => {
    return await axios.get(`${URLAPI}/business/rechercherlisteemployes?page=0&size=10`);
}

export const enregistreremployes = async (formData) => {
    return await axios.post(`${URLAPI}/business/enregistreremployes`, formData);
}

export const verifierfichier = async (formData) => {
    return await axios.post(`${URLAPI}/business/verifierfichier`, formData);
}

export const rechercheremployeparparam = async (param) => {
    return await axios.post(`${URLAPI}/business/rechercheremploye?param=${param}`, {});
}