import UploadModal from 'file-upload-modal'
import React,{ useEffect, useState }  from 'react'
import Layouts from '../layouts/Layouts'
import  {rechercherTickets, rechercherticketsparparam} from '../../services/billeteries/BilleterieRequest'
import UploadFile from '../UploadFile'
import BilleterieOrganisation from './BilleterieOrganisateur'
import BilleterieEntreprise from './BilleterieEntreprise'
import { NavLink } from 'react-router-dom'
import DistribuerTicket from './DistribuerTicket'
import Pagination from '../Pagination'

// export const Upload = () => {
//     const handleSave = (data) => {
//         console.log(data)
//     }
//     return (
//         <div className='mt-5 d-flex justify-content-end mx-5'>
//             <UploadModal buttonComponent={<button className='btn bg-navbar text-white'> <i className="bi bi-file-earmark-arrow-up-fill"></i> Distribuer</button>} onSave={handleSave} />
//         </div>
//     )
// }

function Billeterie() {


  let [ticketsPartenaires,setTicketsPartenaires] = useState([]);
  let [idPartenaire, setidPartenaire] = useState("")
  
  const recherticketparparam = (param)=>{
    rechercherticketsparparam(param).then((reponse)=>{
      setTicketsPartenaires(reponse.data.donnee)
    })
  }

  const rechercherListeTickets = () =>{

    rechercherTickets().then((reponse)=>{
      setTicketsPartenaires(reponse.data.donnee)
    })

  }
  useEffect(() => {
    rechercherListeTickets();
  }, []);

  return (
    <Layouts>
      <div className='mx-3 mt-3'>
        <div className='mx-3 mt-3'>

          <div className='d-flex py-2 justify-content-start'>
            <div></div>
            <div>
              <div className="input-group input-group-sm mb-1">
                <input type="text" onChange={(e)=>recherticketparparam(e.target.value)} className="form-control" placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
                <div className="input-group-prepend">
                  <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
                </div>
              </div>
            </div>
            <div>

            </div>
          </div>

          <table className='table table-hover table-bordered '>
            <thead>
              <tr className=' text-white text-bold bg-navbar'>
                <th>Partenaire</th>
                <th>Tickets reçu</th>
                <th>Tickets distribué</th>
                <th>Tickets restant</th>
                <th className='text-center'>Actions rapides</th>
              </tr>
            </thead>
            <tbody>
              {
                ticketsPartenaires.map((ticket,i)=>(
                  <tr key={i}>
                    <td>{ticket.organisateur}</td>
                    <td width="12%">{ticket.quantite}</td>
                    {/* <td width="12%"> <NavLink to={`/billeterie/beneficiaires/${ticket.id}`}> <button className='btn bg-navbar btn-sm mx-1 text-white'> {ticket.nombreTicketDistribues} <i className="bi bi-arrow-up-right"></i></button></NavLink> </td> */}
                    <td width="12%"> {ticket.nombreTicketDistribues}  </td>
                    <td width="12%">{ticket.quantite - ticket.nombreTicketDistribues} </td>
                    <td width="15%" className='text-center'>
                      <NavLink to={`/billeterie/${ticket.id}`}><button className='btn bg-navbar btn-sm mx-1 text-white'><i className="bi bi-eye"></i> Voir </button></NavLink>
                      <button data-bs-toggle="modal" data-bs-target="#distribution" onClick={()=>setidPartenaire(ticket.id)} className='bg-navbar btn btn-sm text-white'> <i className="bi bi-share"></i> Distribuer</button>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <DistribuerTicket recherchertickets={rechercherListeTickets} idPartenaire={idPartenaire} />
          <div className='d-flex justify-content-end'>
           {/* <Pagination /> */}
          </div>

        </div>
      </div>
    </Layouts>
  )
}

export default Billeterie