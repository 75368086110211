import React from 'react'
import { Navigate, Route, Routes,Switch } from 'react-router-dom'
import BeneficiaireTickets from './components/Billeterie/BeneficiaireTickets'
import Billeterie from './components/Billeterie/Billeterie'
import DetailsBilleterie from './components/Billeterie/DetailsBilleterie'
import DetailsBilleterieEntreprise from './components/Billeterie/DetailsBilleterieEntreprise'
import Employes from './components/Employes/Employes'
import Login from './components/login/Login'
import Partenaire from './components/Partenaire/Partenaire'
import Utilisateurs from './components/utilisateurs/Utilisateurs'
import Evenement from './components/Evenements/Evenement'
import Entreprises from './components/entreprises/Entreprises'
import DetailsBilleterieOrganisateur from './components/Billeterie/DetailsBilleterieOrganisateur'
import { Outlet } from 'react-router-dom'
import { isAuthenticated } from './components/utils/TokenValidation'

const ProtectedRoute = () => {
    return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}

function RouterApp() {
    return (
        <Routes>
            <Route exact path='/login' element={<Login />} />
            <Route exact path="/" element={<ProtectedRoute />}>
                <Route exact path="/" element={<Navigate to="/partenaires" />} />
            </Route>
            <Route exact path="/utilisateurs" element={<ProtectedRoute />}>
                <Route exact path="/utilisateurs" element={<Utilisateurs />} />
            </Route> <Route exact path="/entreprises" element={<ProtectedRoute />}>
                <Route exact path="/entreprises" element={<Entreprises />} />
            </Route>
            <Route exact path="/events" element={<ProtectedRoute />}>
                <Route exact path="/events" element={<Evenement />} />
            </Route>
            <Route exact path="/billeterie" element={<ProtectedRoute />}>
                <Route exact path="/billeterie" element={<Billeterie />} />
            </Route>
            <Route exact path="/billeterie/beneficiaires/:idPartenaire" element={<ProtectedRoute />}>
                <Route exact path="/billeterie/beneficiaires/:idPartenaire" element={<BeneficiaireTickets />} />
            </Route>
            <Route exact path="/billeterie/:details" element={<ProtectedRoute />}>
                <Route exact path="/billeterie/:details" element={<DetailsBilleterie />} />
            </Route>
            <Route exact path="/billeterie/:details/:idEvent" element={<ProtectedRoute />}>
                <Route exact path="/billeterie/:details/:idEvent" element={<DetailsBilleterieEntreprise />} />
            </Route>
            <Route exact path="/billeterie/organisateur/:idEvent" element={<ProtectedRoute />}>
                <Route exact path="/billeterie/organisateur/:idEvent" element={<DetailsBilleterieOrganisateur />} />
            </Route>
            <Route exact path="/partenaires" element={<ProtectedRoute />}>
                <Route exact path='/partenaires' element={<Partenaire />}/>
            </Route>
            <Route exact path="/employes" element={<ProtectedRoute />}>
                <Route exact path='/employes' element={<Employes />}/>
            </Route>

        </Routes>
    )
}

export default RouterApp