import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import logo_skanticket from '../../images/logo_skanticket.jpeg';
import * as yup from 'yup';
import { signin } from '../../services/utilisateurs/UtilisateurRequest';
import { isAuthenticated } from '../utils/TokenValidation';
import { Navigate } from 'react-router-dom';

function Login(props) {

    const [loading, setloading] = useState(false);
    const [messageError, setmessageError] = useState("");

    const initialeValues = {
        "username": "",
        "password": ""
    };

    const validationSchemaValues = yup.object().shape({
        "username": yup.string().required("Veuillez saisir votre nom d'utilisateur"),
        "password": yup.string().required("Veuillez saisir votre mot de passe")
    });

    const onSubmitForm = (values) => {
        setloading(true);
        signin(values).then((res) => {
            console.log("Success");
            console.log(res.data.accessToken);
            setloading(false);
            setmessageError("");
            localStorage.setItem("skanbusinesstoken", res.data.accessToken);
            window.location.replace('/');
        }).catch((error) => {
            setloading(false);
            setmessageError(error.response.data);
        });
    }

    return (
        <>
            {isAuthenticated() && <Navigate to="/"/>}
        <div className='bg-navbar pt-5' style={{ height: "100vh" }}>
            <div className='text-center rounded mt-5 mb-3'>
                <img src={logo_skanticket} width="150px" alt="Logo skanticket" />
            </div>

            <Formik
                initialValues={initialeValues}
                enableReinitialize
                validationSchema={validationSchemaValues}
                onSubmit={onSubmitForm}
            >
                {({ resetForm }) => (

                    <Form>

                        {messageError !== "" && <div className="alert py-3 container alert-danger alert-dismissible fade show" role="alert">
                            {messageError}
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                        <div className='mx-auto' style={{ width: "30%" }}>
                            <div className='mb-3'>
                                <label htmlFor="username" className='label-control mb-2 text-white'>Nom d'utilisateur</label>
                                <Field className='form-control' placeholder="Veuillez entrer votre nom d'utilisateur" id='username' name='username' type="text" />
                                <p className='text-danger'><ErrorMessage name='username'></ErrorMessage></p>
                            </div>
                            <div className='mb-3 '>
                                <label htmlFor="password" className='label-control text-white mb-2'>Mot de passe</label>
                                <Field className='form-control' placeholder='Veuillez entrer votre mot de passe' id='password' name='password' type="password" />
                                <p className='text-danger'><ErrorMessage name='password'></ErrorMessage></p>
                            </div>
                            <div className='text-center '>
                                <button onClick={resetForm} hidden></button>
                                <button type='submit' className='btn my-3 text-black' style={{ backgroundColor: "var(--jaune-skan)", fontWeight: "bold" }}>Se connecter</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    </>
    )
}

export default Login