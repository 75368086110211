import React, { useEffect } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import Layouts from '../layouts/Layouts'
import DistribuerTicket from './DistribuerTicket'

function DetailsBilleterieEntreprise() {
  const {details, idEvent} = useParams();
  
  return (
    <Layouts>
      <div className='m-2'>
        <Link to={"/billeterie/"+details} style={{ color: "#3B546E", borderBottomColor: "white" }}>
          <i className="bi fs-6 bi-arrow-left-circle-fill"></i> Retour
        </Link>
      </div>

      <div className='row mt-3'>
        <div className="card col-md-3 mx-3 shadow mb-3">
          <img style={{ width: "100%" }} src="https://lifemag-ci.com/wp-content/uploads/2020/12/singuila.jpg" className="card-img-top" alt="Concert de Singuila" />
          <div className="card-body">
            <h5 className="card-title">Concert Live de Singuila</h5>
          </div>
          <div className='card-footer d-flex justify-content-between bg-white'>
            <p className="card-text text-bold text-success">2000 tickets <small className='text-secondary'> Restant</small> </p>
            <p className="card-text"><small className="text-muted">Date : 22 Fev 2021</small></p>
          </div>
        </div>

        <div className='col-md-8 '>
          <div className='d-flex mb-3 justify-content-between'>
            <div>
              <h5 className='text-center'>Liste des bénéficiaires de l'évènement</h5>
            </div>
            <div>
              <button data-bs-toggle="modal" data-bs-target="#distribution" className='bg-navbar btn btn-sm text-white'> <i className="bi bi-share"></i> Distribuer des tickets</button>
              <DistribuerTicket />
            </div>
          </div>
          <table className='table table-hover table-bordered '>
            <thead>
              <tr className=' text-white text-bold bg-navbar'>
                <th>Nom</th>
                <th>Contact</th>
                <th width="25%">Nombre de tickets reçu</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>SKAN TECHNOLOGIES</td>
                <td>skanTechnologies@gmail.com</td>
                <td>10</td>
              </tr>
            </tbody>
          </table>
          <div className='d-flex justify-content-end'>
            <nav aria-label="Pagination">
              <ul className="pagination pagination-sm">
                <li className="page-item disabled">
                  <span className="page-link">Previous</span>
                </li>
                <li className="page-item"><a className="page-link" href="#">1</a></li>
                <li className="page-item active">
                  <span className="page-link">
                    2
                    <span className="sr-only"></span>
                  </span>
                </li>
                <li className="page-item"><a className="page-link" href="#">3</a></li>
                <li className="page-item">
                  <a className="page-link" href="#">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

      </div>
    </Layouts>
  )
}

export default DetailsBilleterieEntreprise