import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
import { registreruser } from '../../services/utilisateurs/UtilisateurRequest';

function UserAdd(props) {

    const initialeValues = {
        "id": "",
        "categorie": "",
        "nom": "",
        "email": "",
        "prenom":"",
        "numero":"",
        "password" : ""
    }

    const validationSchemaValues = yup.object().shape({
        "nom": yup.string().required("Veuillez saisir le nom"),
        "email": yup.string().required("Veuillez saisir l'email").email("Veuillez enter un email valide svp"),
        "numero": yup.string().required("Veuillez saisir le numéro"),
        "password": yup.string().required("Veuillez saisir le mot de passe")

    })


    const onSubmitForm = (values) => {
        registreruser(values).then((res)=>{
            props.actualiserliste();
            document.getElementById('fermer').click();
        }).catch((error)=>{

        })
    }

    return (
        <div className="modal fade" id="UserAdd" tabIndex={-1} role="dialog" aria-labelledby="UserAdd" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ajouter un utilisateur</h5>
                        <button type="button" className="btn close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialeValues}
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <div className='mb-3'>
                                        <label className='form-label'>Nom</label>
                                        <Field type="text" className="form-control" placeholder="Veuillez saisir le nom" name="nom" id="nom" />
                                        <p className='text-danger'><ErrorMessage name='nom'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label'>Prenoms</label>
                                        <Field type="text" className="form-control" placeholder="Veuillez saisir le prenom" name="prenom" id="prenom" />
                                        <p className='text-danger'><ErrorMessage name='prenom'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label'>Adresse email</label>
                                        <Field type="email" className="form-control" placeholder="Veuillez saisir une adresse mail" name="email" id="email" />
                                        <p className='text-danger'><ErrorMessage name='email'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label'>Numéro</label>
                                        <Field type="text" className="form-control" placeholder="Veuillez le numéro de téléphone" name="numero" id="numero" />
                                        <p className='text-danger'><ErrorMessage name='numero'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label' htmlFor='password'>Mot de passe</label>
                                        <Field type="password" className="form-control" placeholder="Veuillez le numéro de téléphone" name="password" id="password" />
                                        <p className='text-danger'><ErrorMessage name='password'></ErrorMessage></p>
                                    </div>
                                    
                                    {/* <div className='mb-3'>
                                        <label className='form-label'>Catégorie</label>
                                        <Field component="select" className="form-select" name="categorie" id="categorie">
                                            <option value="">Sélectionner une catégorie</option>
                                            <option value="Entreprise">Entreprise</option>
                                            <option value="Organisateur">Organisateur</option>
                                        </Field>
                                        <p className='text-danger'><ErrorMessage name='categorie'></ErrorMessage></p>
                                    </div> */}
                                    <div className='d-flex justify-content-end'>
                                        <button id='fermer' type="button" onClick={resetForm} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Fermer</button>
                                        <button type="submit" className="btn bg-navbar text-white">Enregistrer</button>
                                    </div>

                                </Form>
                            )}
                        </Formik>

                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserAdd