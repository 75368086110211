import React,{useEffect,useState} from 'react'
import Layouts from '../layouts/Layouts'
import ChargerEmployes from './ChargerEmployes'
import  {rechercherEmployes,rechercheremployeparparam} from '../../services/Employes/EmployeRequest'
import Pagination from '../Pagination';

function Employes() {


    let [employes,setEmployes] = useState([]);

    const rechercherListeEmployes = () =>{
        rechercherEmployes().then((reponse)=>{
            setEmployes(reponse.data.donnee)
        });
    }

    const rechercherEmpparparam = (param) =>{
        rechercheremployeparparam(param).then((reponse)=>{
            setEmployes(reponse.data.donnee)
        });
        
      }

    useEffect(() => {
      rechercherListeEmployes();
    }, []);

    return (
        <Layouts>
            <div className='mx-3 mt-3'>
                <div className='d-flex py-2 justify-content-between'>

                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <input type="text" className="form-control" onChange={(e)=>rechercherEmpparparam(e.target.value)} placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
                            <div className="input-group-prepend">
                                <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
                            </div>
                        </div>
                    </div>

                    <div> <button  data-bs-toggle="modal" data-bs-target="#charger" className='btn bg-navbar text-white'><i className="bi bi-cloud-arrow-up"></i> Charger employés</button> </div>
                </div>

                <table className='table table-hover table-bordered '>
                    <thead>
                        <tr className=' text-white text-bold bg-navbar'>
                            <th>Nom & Prénoms</th>
                            <th>Numéro</th>
                            <th>Email</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                           {
                               employes.map((emp,i)=>(
                                <tr key={i}>
                                    <td>{emp.nomPrenoms}</td>
                                    <td>{emp.numero}</td>
                                    <td>{emp.email}</td>
                                </tr>
                               ))
                           }

                    </tbody>
                </table>
                <ChargerEmployes actualiserliste={rechercherListeEmployes} />
                {/* <div className='d-flex justify-content-end'>
                    <Pagination />
                </div> */}

            </div>
        </Layouts>
    )
}

export default Employes