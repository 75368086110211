import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo_skanticket from '../../images/logo_skanticket.jpeg'
function NavBar(props) {
    
    const deconnexion = () => {
        localStorage.removeItem("skanbusinesstoken");
        window.location.replace('/login');
    }
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
            <NavLink className="navbar-brand px-2" to="/"> <img src={logo_skanticket} width="130" alt='Logo skanticket'/> </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-between" id="navbarText">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/events">Evénèments</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink className="nav-link" to="/partenaires">Partenaires</NavLink>
                    </li>
                    <li className="nav-item">
                    <NavLink className="nav-link" to="/billeterie">Billetterie</NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/utilisateurs">Utilisateurs</NavLink>
                    </li>
                     {/* <li className="nav-item">
                        <NavLink className="nav-link" to="/entreprises">Entreprises</NavLink>
                    </li> */}
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/employes">Employés</NavLink>
                    </li>
                </ul>
                <div className='' >
                    <button onClick={deconnexion} className='btn btn-sm mx-3 btnDeconnexion'><i className="bi bi-box-arrow-right"></i> Déconnexion</button>
                </div>
            </div>
        </nav>
    )
}

export default NavBar