import React, { useEffect, useState } from 'react'
import { rechercherevents } from '../../services/evenements/Evenement';
import UploadFile from '../UploadFile'
import {Oval} from 'react-loader-spinner';
import { distribuerticket } from '../../services/billeteries/BilleterieRequest';
import $ from 'jquery';

function DistribuerTicket(props) {
    let [infoFileUploaded, setinfoFileUploaded] = useState([]);
    const [Isevenement, setIsevenement] = useState(false);
    const [IsAllEmployes, setIsAllEmployes] = useState(false);
    const [listeEvent, setlisteEvent] = useState([]);
    const [loading, setloading] = useState(false);
    const [nombreticket, setnombreticket] = useState("");
    let [evenementId, setevenementId] = useState("");
    let [typeFichier, setTypeFichier] = useState("FICHIER");

    const rechercherEvents = ()=>{
        rechercherevents().then((reponse)=>{
            setlisteEvent(reponse.data.donnee);
        });
    };

    const submitDistributionticket = () =>{

        setloading(true);
        if (!Isevenement) {
            evenementId = "";
            setevenementId(evenementId);
        }

        if (IsAllEmployes) {
            infoFileUploaded = [];
            setinfoFileUploaded(infoFileUploaded);
        }

        let param = new FormData();
        //infoFileUploaded.length !== 0 && param.append("fichiercsv", infoFileUploaded[0]);
        infoFileUploaded.length !== 0 && param.append("fichiercsv", infoFileUploaded);
        //param.append("fichiercsv", null);
        param.append("evenementId", evenementId);
        param.append("nombreticket", nombreticket);
        param.append("tousLesEmploye", IsAllEmployes);
        param.append("typeFichier", typeFichier);
        param.append("id", props.idPartenaire);

        distribuerticket(param).then((response)=>{
            console.log(props)
            props.recherchertickets();
            $("#close").trigger("click");
            
           setloading(false);
            setevenementId("");
            setnombreticket("");
            setIsAllEmployes(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `tickets.xlsx`); //or any other extension
            document.body.appendChild(link);
            link.click();
           
        }).catch((error)=>{
            setloading(false);
            console.log(error.response.data.donnee);
        })


    }

    useEffect(() => {
        rechercherEvents()
        console.log(props)
    }, []);

    const changerFichier = (data)=>{
        console.log("DATA VENANT COMPOSENT ENFANT");
        console.log(data);
    }
    

    return (
        <div className="modal fade" id="distribution" tabIndex={-1} role="dialog" aria-labelledby="distribution" aria-hidden="true" >
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Distribution des tickets</h5>
                        <button type="button" id="close" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>

                    <div className="modal-body">
                    
                    <label>Type envoi</label>
                    {!IsAllEmployes &&
                        <select value={typeFichier} onChange={(e)=>setTypeFichier(e.target.value)} className='form-select mt-2'>
                            <option value="FICHIER">Fichier</option>
                            <option value="SMS">Numéro</option>
                            <option value="EMAIL">Email</option>
                        </select>
                    }
                    <div className="form-check mb-3 form-switch mt-2">
                            <input className="form-check-input" onChange={() => setIsAllEmployes(!IsAllEmployes)} type="checkbox" id="allemployes" checked={IsAllEmployes} />
                            <label htmlFor="">Distribuer à tous les employés </label>
                        </div>
                        {
                            !IsAllEmployes &&
                            <UploadFile setinfoFile={setinfoFileUploaded} miseAjour={changerFichier} />
                        }
                        
                    </div>
                    <div className='mb-3'>
                        
                        <div className="form-check mb-3 mx-3 form-switch">
                            <input className="form-check-input" onChange={() => setIsevenement(!Isevenement)} type="checkbox" id="flexSwitchCheckChecked" checked={Isevenement} />
                            <label htmlFor="">Distribuer pour un évènement précis </label>
                        </div>
                        {Isevenement && <div className='mb-3 mx-3'>
                            <label htmlFor="evenement" className='form-label'>Evenement</label>
                            <select onChange={(e)=>setevenementId(e.target.value)} value={evenementId} className='form-control form-select' name="evenement" id='evenement'>
                                <option value="">Sélectionner l'évènement</option>
                               { listeEvent.map((item,index)=>(
                                    <option key={index} value={item.id}>{item.designation}</option>
                                ))}
                            </select>
                        </div>}
                    </div>
                    <div className='mb-3 mx-3'>
                        <label htmlFor="nbTicket" className='label-control'>Nombre de tickets par personne </label>
                        <input className="form-control" onChange={(e)=>setnombreticket(e.target.value)} value={nombreticket}  type="number" id="nbTicket" />
                    </div>
                    <div className="modal-footer text-end">
                    {loading ? <Oval with="30" height="30" color="#344b7b" visible={loading}/> :
                        <button type='submit' onClick={submitDistributionticket} className='btn bg-navbar text-white'>Valider</button>
                    }
                    </div>
                </div>
            </div>
        </div >
    )
}
export default DistribuerTicket