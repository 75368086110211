import React,{ useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Layouts from '../layouts/Layouts'

import  {rechercherpartenaireparparam, rechercherPartenaires} from '../../services/partenaires/PartenaireRequest'

function Partenaire() {

    let [partenaires, setPartenaires] = useState([])

    const rechercherpartenaireparparm = (param)=>{
        rechercherpartenaireparparam(param).then((reponse)=>{
            setPartenaires(reponse.data.donnee);
        })
    }
    
    const rechercherListePartenaires = () => {
        rechercherPartenaires().then((reponse) => {
            setPartenaires(reponse.data.donnee);
        })
    }
    useEffect(() => {
        rechercherListePartenaires();
    }, []);

    return (
        <Layouts>
            <div className='mx-3 mt-3'>
                {/* <div className='d-flex'>
      <p>Nombre d'entreprises : <span className='nb'> 100</span></p>
      <p className='mx-3'>Nombre d'organisateurs : <span className='nb'> 100</span></p>
    </div> */}
                <div className='d-flex py-2 justify-content-start'>
                    <div></div>
                    <div>
                        <div className="input-group input-group-sm mb-1">
                            <input type="text" className="form-control" onChange={(e)=>rechercherpartenaireparparm(e.target.value)} placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
                            <div className="input-group-prepend">
                                <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
                            </div>
                        </div>
                    </div>
                    <div>

                    </div>
                </div>

                <table className='table table-hover table-bordered '>
                    <thead>
                        <tr className=' text-white text-bold bg-navbar'>
                            <th>Partenaire</th>
                           
                            <th className='text-center'>Actions rapides</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            partenaires.map((partenaire,i)=>(
                                <tr key={i}>
                                    <td>{partenaire.designation}</td>
                                    <td width="15%" className='text-center'> <NavLink to="/billeterie"><button className='btn bg-navbar text-white'><i className="bi bi-ticket"></i> Tickets</button></NavLink> </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                {/* <div className='d-flex justify-content-end'>
                    <nav aria-label="Pagination">
                        <ul className="pagination pagination-sm">
                            <li className="page-item disabled">
                                <span className="page-link">Previous</span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active">
                                <span className="page-link">
                                    2
                                    <span className="sr-only"></span>
                                </span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div> */}

            </div>
        </Layouts>
    )
}

export default Partenaire