import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import * as yup from 'yup'
function EntrepriseAdd() {
 
    const initialeValues = {
        "id": "",
        "nom": "",
        "email": ""
    }

    const validationSchemaValues = yup.object().shape({
        "nom": yup.string().required("Veuillez saisir le nom"),
        "email": yup.string().required("Veuillez saisir l'email").email("Veuillez enter un email valide svp")
    })


    const onSubmitForm = (values) => {
        console.log(values)
    }

    return (
        <div className="modal fade" id="EntrepriseAdd" tabIndex={-1} role="dialog" aria-labelledby="EntrepriseAdd" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Ajouter une entreprise</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        </button>
                    </div>
                    <div className="modal-body">

                        <Formik
                            initialValues={initialeValues}
                            validationSchema={validationSchemaValues}
                            onSubmit={onSubmitForm}
                        >
                            {({ resetForm }) => (
                                <Form>
                                    <div className='mb-3'>
                                        <label className='form-label'>Nom</label>
                                        <Field type="text" className="form-control" placeholder="Veuillez saisir un nom" name="nom" id="nom" />
                                        <p className='text-danger'><ErrorMessage name='nom'></ErrorMessage></p>
                                    </div>
                                    <div className='mb-3'>
                                        <label className='form-label'>Adresse email</label>
                                        <Field type="email" className="form-control" placeholder="Veuillez saisir une adresse mail" name="email" id="email" />
                                        <p className='text-danger'><ErrorMessage name='email'></ErrorMessage></p>
                                    </div>
                                   
                                    <div className='d-flex justify-content-end'>
                                        <button type="button" onClick={resetForm} className="btn btn-secondary mx-2" data-bs-dismiss="modal">Fermer</button>
                                        <button type="submit" className="btn bg-navbar text-white">Enregistrer</button>
                                    </div>

                                </Form>
                            )}
                        </Formik>

                    </div>
                    <div className="modal-footer">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default EntrepriseAdd