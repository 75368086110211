import React,{ useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import Layouts from '../layouts/Layouts'
import { useParams } from 'react-router-dom';
import  {rechercherbeneficiairestickets} from '../../services/billeteries/BilleterieRequest'
function BeneficiaireTickets(props) {

    const params = useParams()
    let [partenaire, setPartenaire] = useState({})
    let [beneficiaires, setBeneficiaires] = useState([])

    useEffect(() => {
        //console.log(params.idPartenaire)
        rechercherbeneficiaire(params.idPartenaire);
      }, [])

    const rechercherbeneficiaire = (id)=>{
        rechercherbeneficiairestickets(id).then((reponse)=>{
          console.log("BENEFICIAIRES")
          setPartenaire(reponse.data.donnee.commande);
          beneficiaires = reponse.data.donnee.participants;
          setBeneficiaires(beneficiaires);
          console.log(beneficiaires)
          
        }).catch(error=>{
          console.log("Erreur lors de la recupération des informations");
        })
      }

    return (
        <Layouts>
            <div className='m-2'>
                <Link to={"/billeterie"} style={{ color: "#3B546E", borderBottomColor: "white" }}>
                    <i className="bi fs-6 bi-arrow-left-circle-fill"></i> Retour
                </Link>
            </div>
            <div className='mx-3 mt-3'>

                <div className='d-flex pt-2 justify-content-between'>
                    <div>
                        <h6 className='mx-2'>Liste des bénéficiaires</h6>
                    </div>
                    <div className=''>
                        Partenaire : <span className='fw-bold'>{partenaire.organisateur}</span> <i className="bi bi-slash-lg"></i> Tickets distribué : <span className='fw-bold'>{partenaire.nombreTicketDistribues}</span>
                    </div>

                    {/* <div>
                        <div className="input-group input-group-sm mb-1">
                            <input type="text" className="form-control" placeholder='Recherche ...' aria-label="Recherche" aria-describedby="Input-group" />
                            <div className="input-group-prepend">
                                <span className="input-group-text  bg-navbar" id="inputGroup-sizing-sm"><i className=" text-white bi bi-search"></i></span>
                            </div>
                        </div>
                    </div> */}

                </div>

                <table className='table table-hover table-bordered '>
                    <thead>
                        <tr className=' text-white text-bold bg-navbar'>
                            <th>Nom</th>
                            <th>Contact</th>
                            <th>Tickets reçu</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>SKAN TECHNOLOGIES</td>
                            <td width="30%">contact@skanci.com</td>
                            <td width="10%">2</td>

                        </tr>
                    </tbody>
                </table>

                {/* <div className='d-flex justify-content-end'>
                    <nav aria-label="Pagination">
                        <ul className="pagination pagination-sm">
                            <li className="page-item disabled">
                                <span className="page-link">Previous</span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                            <li className="page-item active">
                                <span className="page-link">
                                    2
                                    <span className="sr-only"></span>
                                </span>
                            </li>
                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                            <li className="page-item">
                                <a className="page-link" href="#">Next</a>
                            </li>
                        </ul>
                    </nav>
                </div> */}

            </div>
        </Layouts>
    )
}

export default BeneficiaireTickets